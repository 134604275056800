import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
//import Spinner from "react-bootstrap/Spinner";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 950,
    minHeight: "100vh",
    backgroundColor: "var(--trade-body)",
  },
  container: {
    height: "auto",
    backgroundColor: "var(--trade-body)",
    width: "100%",
    padding: "2rem",
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "var(--trade-body)",
    marginBottom: "1rem",
  },
  headers: {
    color: "var(--trade-primary)",
  },
  description: {
    color: "var(--trade-font)",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    height: "auto",
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "var(--trade-font)",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

function SafetyMeetingSlider(props) {
  const [safetyMeeting, setSafetyMeeting] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    setSafetyMeeting(props.meeting);
  }, [props.meeting]);

  const fieldFormList = () => {
    return (
      <Grid container item md={12} className={classes.container}>
        {props.item
          ? Object.values(props.item.fields).map((elem) => {
            return (
              <Grid
                container
                item
                md={12}
                className={classes.tableRow}
                key={elem.title}
              >
                <Grid item md={4}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography variant="body1">
                      {elem.title}
                    </WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography variant="body1">
                      {elem.type}
                    </WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography variant="body1">
                      {elem.field_value}
                    </WhiteTextTypography>
                  </Box>
                </Grid>
              </Grid>
            );
          })
          : safetyMeeting.fields.map((elem) => {
            const ls = [];
            elem.options.split("\r\n").forEach((e) => {
              var temp = e.split("|")[0];
              ls.push(temp);
            });

            let prettyOptions = ls.reduce((prev, next) => prev + ", " + next);
            prettyOptions = prettyOptions.slice(0, prettyOptions.length);
            return (
              <Grid
                container
                item
                md={12}
                className={classes.tableRow}
                key={elem.title}
              >
                <Grid item md={4}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography variant="body1">
                      {elem.title}
                    </WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography variant="body1">
                      {elem.type}
                    </WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography variant="body1">
                      {prettyOptions}
                    </WhiteTextTypography>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    );
  };

  const safetyMeetingInfo = () => {
    return (
      <Grid container className={classes.container}>
        {props.pdfPath ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: "0.8rem 1.1rem" }}
            onClick={() => window.open(props.pdfPath)}
          >
            View PDF
          </Button>
        ) : null}
        <Grid container item md={12}>
          <Grid item md={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <AmberTypography variant="h4" className={classes.headers}>
                Title:{" "}
              </AmberTypography>
              <WhiteTextTypography variant="h4" style={{ marginLeft: "1rem" }}>
                &#9;&#9; {safetyMeeting.title}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item md={12}>
          <Grid item md={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <AmberTypography variant="h4" className={classes.headers}>
                Code:{" "}
              </AmberTypography>
              <WhiteTextTypography variant="h4" style={{ marginLeft: "1rem" }}>
                &#9;&#9; {safetyMeeting.code}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Box m={2}>
            {/* <p 
                            className={classes.description} 
                            dangerouslySetInnerHTML={{ __html: safetyMeeting.description, style: {color: "var(--trade-font)"} }}>
                        </p> */}
            <pre style={{ color: "var(--trade-font)" }} className={classes.description}>
              {safetyMeeting.description}
            </pre>
          </Box>
        </Grid>
        <Grid container item md={12}>
          <Grid item md={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography variant="h5" className={classes.headers}>
                Question
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography variant="h5" className={classes.headers}>
                Question Type
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography variant="h5" className={classes.headers}>
                Answers
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        {fieldFormList()}
      </Grid>
    );
  };

  const drawerContent = () => {
    return (
      <div className={classes.mainContainer}>
        {safetyMeeting !== null ? (
          safetyMeetingInfo()
        ) : (
          <div> No Info available </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <React.Fragment>
        {props.whiteIcon ? (
          <WhiteTextTypography onClick={openDrawer}>
            <ChevronRightIcon />
          </WhiteTextTypography>
        ) : (
          <Typography onClick={openDrawer}>
            <ChevronRightIcon />
          </Typography>
        )}

        <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
          {drawerContent()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

// const mapStateToProps = () => {}

// const mapDispatchToProps = () => {}

//export default connect(mapStateToProps, mapDispatchToProps)(SafetyMeetingSlider);
export default SafetyMeetingSlider;
