/* global google */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Typography, Box, MenuItem, Select } from "@material-ui/core";
import { withStyles, styled, makeStyles } from "@material-ui/core/styles";
import { ax } from "utils";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Avatar from "@material-ui/core/Avatar";
import Gravatar from "react-gravatar";
import { getGravatarUrl } from "components/Dashboard/Map";
import { compose } from "recompose";
import AppBar from "@material-ui/core/AppBar";
import Moment from "react-moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { time_logs, projects } from "actions";
import SideDrawerWorker from "../SideDrawerWorker/SideDrawerWorker";
import Spinner from "react-bootstrap/Spinner";
import TextField from "@material-ui/core/TextField";
import SideDrawerProject from "../../newComponents/SideDrawerProject/SideDrawerProject";

import "./ProjectSlider.css";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
} = require("react-google-maps");

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

var moment = require("moment");

const markerStyling = {
  display: "none",
};

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "white",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  drawerBody: {
    //maxWidth: "40%",
    backgroundColor: "var(--trade-body)",
    minHeight: "100vh",
    width: "70vw",
    //padding: "1rem",
    //position: "relative",
  },
  subheader: {
    //marginRight: "0.5rem",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "var(--trade-font)",
  },
  bodyText: {
    fontSize: "1rem",
    color: "var(--trade-font)",
  },
  centeredText: {
    margin: "auto",
  },
  textContainer: {
    textAlign: "center",
    margin: "auto",
  },
  mainContainer: {
    //width: 1050,
    width: "100%",
    backgroundColor: "var(--trade-body)",
  },
  headers: {
    margin: "auto 2rem",
  },
  smallerText: {
    fontSize: "0.8rem",
  },
  tableRow: {
    backgroundColor: "var(--trade-background)",
    height: "3rem",
    alignItems: "center",
    borderTop: "solid 2px black",
  },
  tableHeaders: {
    backgroundColor: "var(--trade-background)",
    margin: "0.5rem",
    height: "3rem",
    alignItems: "center",
  },
  topContainer: {
    margin: "2rem",
  },
  rootTab: {
    flexGrow: 1,
    width: "100%",
    padding: "10px",
    backgroundColor: "var(--trade-background)",
    border: "0.08em solid var(--trade-primary)",
    borderRadius: "10px",
  },
}));
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    // TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "var(--trade-success)",
    height: "80%",
    zIndex: "1",
    bottom: "10%",
    borderRadius: "10px",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "var(--trade-success)",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: "#000000",

    "&.MuiTab-root": {
      minWidth: "120px",
    },
    "&.Mui-selected": {
      color: "var(--trade-font)",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#000000",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

//const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");

export const ScrollableTabsButtonAuto = (props) => {
  const classes = useStyles();

  //const { files, notes, GPSentries, timesheets, safety_meetings_project } = props;
  const { project_id, currentYear } = props;
  const [monthList, setMonthList] = useState([]);

  useEffect(() => {
    //setFilteredFiles(tempFiles);
    var temps = [];

    const today = new Date();
    if (currentYear === today.getFullYear()) {
      const currentMonth = today.getMonth();
      for (let i = currentMonth; i >= 0; i--) {
        temps.push(`${months[i]} ${currentYear}`);
      }
    } else {
      const lastMonth = 11;
      for (let i = lastMonth; i >= 0; i--) {
        temps.push(`${months[i]} ${currentYear}`);
      }
    }
    setMonthList(temps);
  }, [currentYear]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    "files",
    "notes",
    "photos",
    // "estimates",
    "gps records",
    "Project Time sheet",
    "Project Safety meetings",
  ];

  return (
    <div className={classes.rootTab}>
      <AppBar
        position="static"
        color="default"
        style={{
          backgroundColor: "#000000",
          boxShadow: "none",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--trade-success)",
            },
          }}
          style={{
            background: "#FFFF",
            color: "white",
            minHeight: "30px",
            marginTop: "5px",
            borderRadius: "5px",
            padding: "0 5px",
          }}
        >
          {tabs.map((index, key) => {
            return (
              <StyledTab
                key={key}
                label={index}
                {...a11yProps(key)}
                style={{
                  textTransform: "capitalize",
                  fontSize: "0.8rem",
                  minHeight: "30px",
                  zIndex: "2",
                }}
              />
            );
          })}
        </StyledTabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <div className="projectTimeSheetWrapper" id="style-5">
          {monthList.length > 0 ? (
            // monthList.map((item, key) => (
            <SideDrawerProject
              // key={key}
              // item={item}
              type={"files"}
              project_id={project_id}
              token={props.token}
            />
          ) : (
            // ))
            <span className="files-message">
              No Files Available for this Project.
            </span>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="projectTimeSheetWrapper" id="style-5">
          {monthList.length > 0 ? (
            // monthList.map((item, key) => (
            <SideDrawerProject
              // key={key}
              // item={item}
              type={"notes"}
              project_id={project_id}
              token={props.token}
            />
          ) : (
            // ))
            <span className="files-message">
              No Notes Available for this Project.
            </span>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="projectTimeSheetWrapper" id="style-5">
          {monthList.length > 0 ? (
            // monthList.map((item, key) => (
            <SideDrawerProject
              // key={key}
              // item={item}
              type={"photos"}
              project_id={project_id}
              token={props.token}
            />
          ) : (
            // ))
            <span className="files-message">
              No Files Available for this Project.
            </span>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="projectTimeSheetWrapper" id="style-5">
          {monthList.length > 0 ? (
            monthList.map((item, key) => (
              <SideDrawerProject
                key={key}
                item={item}
                type={"gps-entries"}
                project_id={project_id}
                token={props.token}
              />
            ))
          ) : (
            <span className="files-message">
              No GPS Records Available for Project.
            </span>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div className="projectTimeSheetWrapper" id="style-5">
          {monthList.length > 0 ? (
            monthList.map((item, key) => (
              <SideDrawerProject
                key={key}
                item={item}
                type={"logs"}
                project_id={project_id}
                token={props.token}
              />
            ))
          ) : (
            <span className="files-message">
              No Timesheet Available for this Project.
            </span>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className="projectTimeSheetWrapper" id="style-5">
          {monthList.length > 0 ? (
            monthList.map((item, key) => (
              <SideDrawerProject
                key={key}
                item={item}
                type={"safety-meetings"}
                project_id={project_id}
                token={props.token}
              />
            ))
          ) : (
            <span className="files-message">
              No Safety Meeting Available for this Project.
            </span>
          )}
        </div>
      </TabPanel>
    </div>
  );
};

export const GMap = compose(
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    disableDefaultUI={true}
    defaultZoom={14}
    center={{
      lat: parseFloat(props.latitude),
      lng: parseFloat(props.longitude),
    }}
    defaultOptions={{
      scrollwheel: false,
      disableDefaultUI: false,
      draggable: true,
    }}
  >
    <Circle
      center={{
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude),
      }}
      radius={props.radius}
      visible={true}
      //draggable={true}
      //onDragEnd={props.onDragEnd}
    />

    {/* display on site workers on the map */}
    {props.onSiteWorkers
      ? props.onSiteWorkers.map((worker) => (
          <MarkerWithLabel
            position={{
              lat: parseFloat(worker.last_time_log?.latitude),
              lng: parseFloat(worker.last_time_log?.longitude),
            }}
            labelAnchor={new google.maps.Point(0, 0)}
            labelStyle={markerStyling}
            icon={{
              url: worker.avatar || getGravatarUrl(worker.email),
              scaledSize: new google.maps.Size(32, 32),
            }}
            visible={true}
          >
            <span></span>
          </MarkerWithLabel>
        ))
      : null}
  </GoogleMap>
));

function ProjectSlider(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  //const {projectId} = props.projectId;
  const [project, setProject] = useState(null);
  const [possibleYears, setPossibleYears] = useState([]);
  const [currentYear, setCurrentYear] = useState("");
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState(null);

  const generateYears = () => {
    const company_created = new Date(
      props.user.last_time_log.project.company_created
    );
    const today = new Date();
    const years = [];
    for (let i = company_created.getFullYear(); i <= today.getFullYear(); i++) {
      years.push(i);
    }

    setPossibleYears(years);
  };

  useEffect(() => {
    generateYears();
  }, []);

  useEffect(() => {
    const today = new Date();
    setCurrentYear(today.getFullYear());
  }, [possibleYears]);

  useEffect(() => {
    props.getProjectDetails(props.projectId).then((res) => {
      setProject(res.item);
    });
  }, [props.projectId]);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const workerRow = (worker, key) => {
    return (
      <Grid container item md={12} key={key}>
        <Grid item md={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <Avatar src={worker.avatar} className={classes.centeredText} />
          </Box>
        </Grid>
        <Grid item md={10}>
          <Box m={2}>
            <WhiteTextTypography>
              {worker.first_name} {worker.last_name}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={1}>
          <Box m={2}>
            <WhiteTextTypography>
              <SideDrawerWorker worker_id={worker.id} />
            </WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const onSiteWorkerRow = (worker, key) => {
    function msToTime(duration) {
      var milliseconds = Math.floor((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      if (hours > 0) {
        return hours + " hrs" + minutes + " min";
      } else {
        return minutes + " min";
      }
    }

    const stopTimer = () => {
      const { latitude, longitude, id } = worker.last_time_log;

      props
        .stopTimer(id, parseFloat(latitude), parseFloat(longitude))
        .then(() => {
          setRedirectTo("/project-selection");
        });
    };

    return (
      <Grid container item md={12} className={classes.tableRow} key={key}>
        {/* Worker Name and Avatar*/}
        <Grid container item md={3} style={{ maxHeight: "3rem" }}>
          <Grid item md={5}>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              height="100%"
            >
              <Avatar
                src={worker.avatar}
                className={classes.centeredText}
                style={{ margin: "0" }}
              />
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box m={2} margin="0">
              <WhiteTextTypography
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {worker.first_name} {worker.last_name}
              </WhiteTextTypography>
              <WhiteTextTypography className={classes.smallerText}>
                {worker.phone_number}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        {/* Task */}
        <Grid item md={2}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              {worker.last_time_log?.task_type?.title}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Time In */}
        <Grid item md={2}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              <Moment format={"LT"}>{worker.last_time_log?.start}</Moment>
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Lunch */}
        <Grid item md={1}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              {worker.last_break && worker.last_break?.end
                ? msToTime(
                    Date.parse(worker.last_break.end) -
                      Date.parse(worker.last_break.start)
                  )
                : "N/A"}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Hours */}
        <Grid item md={1}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              <Moment date={worker.last_time_log?.start} durationFromNow />
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Stop Timer */}
        <Grid
          item
          md={2}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Box m={1} style={{ margin: 0 }}>
            <Button
              variant="outlined"
              size="small"
              style={{
                color: "var(--trade-tertiary)",
                borderColor: "var(--trade-tertiary)",
                fontSize: "0.65rem",
              }}
              onClick={stopTimer}
            >
              Stop
            </Button>
          </Box>
        </Grid>
        {/* Dummy */}
        {/* <Grid item md={1}>
          <Box m={2} style={{ margin: 0 }}></Box>
        </Grid> */}
        {/* Details */}
        <Grid item md={1}>
          <Box m={2} style={{ margin: 0 }}>
            <WhiteTextTypography>
              <SideDrawerWorker worker_id={worker.id} />
            </WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const SelectYear = () => {
    return (
      <CssTextField
        variant="outlined"
        label="select year"
        value={currentYear}
        onChange={(ev) => {
          setCurrentYear(ev.target.value);
        }}
        select
        style={{ minWidth: 200 }}
      >
        {possibleYears.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </CssTextField>
    );
  };

  const projectInfo = () => {
    return (
      <Grid container item md={12} className={classes.mainContainer}>
        <Grid container item md={12} className={classes.topContainer}>
          <Grid item md={1}>
            <Box display="flex" justifyContent="center" alignContent="center">
              {project.company_logo ? (
                <Avatar
                  variant="square"
                  src={project.company_logo}
                  style={{ width: "80%", height: "80%", margin: "auto" }}
                />
              ) : (
                <Gravatar
                  email={project.client_email}
                  size={40}
                  style={{ borderRadius: "10%" }}
                />
              )}
            </Box>
          </Grid>
          <Grid container item md={11}>
            <Grid item md={12}>
              <Box className={classes.headers}>
                <WhiteTextTypography variant="h6">
                  {project.title}
                </WhiteTextTypography>
                <WhiteTextTypography>{project.address}</WhiteTextTypography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={12} className={classes.topContainer}>
          <Grid item md={4} sm={12} className={classes.textContainer}>
            <span className={classes.subheader}>Project Manager: </span>
            <span className={classes.bodyText}>
              {project.project_manager
                ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                : "No Project Manager"}
            </span>
          </Grid>
          <Grid item md={4} sm={12} className={classes.textContainer}>
            <span className={classes.subheader}>Company: </span>
            <span className={classes.bodyText}> {project.company_name} </span>
          </Grid>
          <Grid item md={4} sm={12} className={classes.textContainer}>
            <CssTextField
              variant="outlined"
              label="Status"
              value={project.status ? project.status.status : ""}
              onChange={(e) => {
                props.handleChange(e, project);
              }}
              select
              style={{ minWidth: 200 }}
            >
              <MenuItem value={"Not Started"} key={"Not Started"}>
                Not Started
              </MenuItem>
              <MenuItem value={"In Progress"}>In Progress</MenuItem>
              <MenuItem value={"Closed"}>Closed</MenuItem>
              <MenuItem value={"Lost"}>Lost</MenuItem>
              <MenuItem value={"Awarded"}>Awarded</MenuItem>
              <MenuItem value={"Submitted"}>Submitted</MenuItem>
              <MenuItem value={"Revision"}>Revision</MenuItem>
              <MenuItem value={"Submitted review"}>Submitted review</MenuItem>
              <MenuItem value={"Hold"}>Hold</MenuItem>
              <MenuItem value={"Quotation"}>Quotation</MenuItem>
              <MenuItem value={"Invoiced"}>Invoiced</MenuItem>
            </CssTextField>
          </Grid>
        </Grid>

        {project.title !== "Office Work" ? (
          <Grid item md={12}>
            <Box m={2}>
              <GMap
                latitude={project.latitude}
                longitude={project.longitude}
                googleMapURL={process.env.GOOGLE_MAPS_URL}
                loadingElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                radius={project.radius}
                onSiteWorkers={project.users_on_project}
                containerElement={
                  <div
                    style={{
                      height: `250px`,
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                  />
                }
              ></GMap>
            </Box>
          </Grid>
        ) : null}

        <Grid container item md={12}>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Box
              m={1}
              justifyContent="center"
              alignContent="center"
              display="flex"
            >
              {possibleYears.length === 1 ? null : <SelectYear />}
            </Box>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
        <Grid item md={12}>
          <div className="worker-tab-pane">
            <ScrollableTabsButtonAuto
              project_id={project.id}
              currentYear={currentYear}
            />
          </div>
        </Grid>
        {project.users_on_project.length ? (
          <div className="workers-on-site">
            <Grid item md={12}>
              <Box ml={3}>
                <WhiteTextTypography variant="h5">
                  Who's On Site
                </WhiteTextTypography>
              </Box>
            </Grid>
            <Grid
              container
              item
              md={12}
              className={classes.tableHeaders}
              style={{ margin: 0 }}
            >
              {/* Table Headers */}
              <Grid item md={3}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Team Assigned</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={3}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Task</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Time In</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={1}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Lunch</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Hours</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={1}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                ></Box>
              </Grid>
              <Grid item md={1}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0"
                >
                  <WhiteTextTypography>Details</WhiteTextTypography>
                </Box>
              </Grid>
            </Grid>
            <Grid container item md={12}>
              {project.users_on_project.map((elem, key) =>
                onSiteWorkerRow(elem, key)
              )}
            </Grid>
          </div>
        ) : null}
        <Grid item md={12}>
          <Box ml={3}>
            <WhiteTextTypography variant="h5">
              Assigned Workers
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid container item md={12} className={classes.topContainer}>
          {project.workers.map((elem, key) => workerRow(elem, key))}
        </Grid>
      </Grid>
    );
  };

  const drawerContent = () => {
    return (
      <Grid container className={classes.drawerBody}>
        {project !== null ? projectInfo() : <div> No Info available </div>}
      </Grid>
    );
  };

  if (redirectTo !== null) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div>
      <React.Fragment>
        <WhiteTextTypography onClick={openDrawer}>
          <ChevronRightIcon />
        </WhiteTextTypography>
        <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
          {drawerContent()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stopTimer: (time_log_id, latitude, longitude) => {
      return dispatch(time_logs.stopTimeLog(time_log_id, latitude, longitude));
    },
    getProjectDetails: (project_id) => {
      return dispatch(projects.getProjectDetails(project_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSlider);
